.char-arrange {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  border-radius: 0.7rem;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

.hero-box {
  margin: 5px 5px;
}

.hero-box:hover {
  transform: scale(1.2, 1.2);
  transition-duration: 1s;
  z-index: 1;
}

.hero-box:hover .character-names-versus {
  display: block;
}

.character-names-versus {
  display: none;
  color: white;
  font-size: 14px;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
}

.pick-hero {
  display: flex;
  justify-content: space-between;
}

.left-hero {
  width: 400px;
  height: 450px;
  margin: 40px 40px 65px 15px;
  border-radius: 1rem;
  background-color: rgb(0, 0, 0, 0.5);
}

.left-sp {
  width: 400px;
  height: 450px;
  border-radius: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.right-hero {
  width: 400px;
  height: 450px;
  margin: 40px 15px 65px 30px;
  border-radius: 1rem;
  background-color: rgb(0, 0, 0, 0.5);
}

.right-sp {
  width: 400px;
  height: 450px;
  border-radius: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.first-box {
  display: flex;
  align-content: flex-end;
}

.right-arrange {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
}

.second-box {
  display: flex;
  align-content: flex-start;
}

.stats-left-hero1 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 150px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-left-hero2 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-right-hero1 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-right-hero2 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.leftImageOverlay {
  display: flex;
  color: white;
  justify-content: flex-start;
  align-content: flex-start;
}

.player1Name {
  font-size: 2vw;
  color: black;
  text-shadow: 0 0 10px white, 0 0 10px white;
  font-family: "Bangers", cursive;
}

.player2Name {
  font-size: 2vw;
  color: black;
  text-shadow: 0 0 10px white, 0 0 10px white;
  font-family: "Bangers", cursive;
}

.rightImageOverlay {
  display: flex;
  color: white;
  justify-content: flex-end;
  align-content: flex-end;
}

.versus-image {
  margin-top: 10vh;
  width: 50%;
  height: 50%;
}

.overall-pos1 {
  padding-top: 10px;
  font-size: 2vw;
  color: white;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.overall-pos2 {
  padding-top: 10px;
  font-size: 2vw;
  color: white;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.background {
  background-image: url("https://cutewallpaper.org/21/fire-background-gif/Fire-Fighting-GIF-Find-Share-on-GIPHY.gif");
  background-size: cover;
  position: sticky;
}

.mid-section {
  color: white;
  padding-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar-versus {
  background-color: rgb(0, 0, 0, 0.6);
  padding-top: 3px;
  border-radius: 5px;
  width: 13.5vw;
  height: 4vh;
}

@media screen and (max-width: 480px) {
  .char-arrange {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto auto;
    border-radius: 0.7rem;
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
  }
  .left-hero {
    width: 100px;
    height: 120px;
    margin: 40px 5px 65px 5px;
    border-radius: 1rem;
    background-color: rgb(0, 0, 0, 0.5);
  }
  .left-sp {
    width: 100px;
    height: 120px;
    border-radius: 1rem;
    box-shadow: 2px 3px 5px 1px;
  }
  .stats-left-hero1 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 150px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-left-hero2 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-right-hero1 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-right-hero2 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }
  .versus-image {
    margin-top: 8vh;
    width: 30px;
    height: 30px;
  }
  .right-hero {
    width: 100px;
    height: 120px;
    margin: 40px 5px 65px 5px;
    border-radius: 1rem;
    background-color: rgb(0, 0, 0, 0.5);
  }

  .right-sp {
    width: 100px;
    height: 120px;
    border-radius: 1rem;
    box-shadow: 2px 3px 5px 1px;
  }
  .versus-div {
    width: 100vw;
  }
  .search-bar-versus {
    background-color: rgb(0, 0, 0, 0.6);
    padding-top: 3px;
    border-radius: 5px;
    width: 60vw;
    height: 5vh;
  }
}
