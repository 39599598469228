.bg {
  background-image: url("./Media/bgf.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.comment-box {
  background-color: black;
  box-shadow: 20px 20px 10px 0 rgb(0, 0, 0, 0.2);
  color: #66fcf1;
  width: 80%;
  margin-left: 10%;
  transition: 0.3s;
  /* display: inline-flex; */
  padding: 2rem;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
.comment-box:hover {
  opacity: 1;
}

.haha {
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

.username-box {
  width: 90%;
  margin-left: 5%;
  margin-top: 50px;
  margin-bottom: 80px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 8px 10px rgba(102, 252, 241, 0.2);
}

#incomment {
  width: 70%;
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 25px;
  text-align: center;
}

.namein {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  text-align: center;
  border-radius: 25px;
  width: 15rem;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .namein {
    width: 10rem;
  }

  #incomment {
    text-align: center;
  }

  #commentbtn {
    width: 5rem;
  }
}

@media screen and (max-width: 300px) and (min-width: 0px) {
  .namein {
    width: 6rem;
  }
  #commentbtn {
    width: 2rem;
    text-size-adjust: 2rem;
  }
}

#commentbtn {
  margin-top: 15px;
  margin-bottom: 15px;
}

.usercomments-box {
  box-shadow: 10px 10px 8px 0 rgba(255, 255, 255, 0.2);
  width: 80%;
  margin-left: 10%;
  transition: 0.3s;
  color: #45a29e;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.usercomments-box:hover::before {
  /* transform: scale(1.1); */
  box-shadow: 0 0 15px #66fcf1;
}

.usercomments-box:hover {
  transform: scale(1.3);
  color: #66fcf1;
  box-shadow: 0 0 5px #66fcf1;
  text-shadow: 0 0 5px #66fcf1;
}

#name {
  font-size: 2vw;
}

#comment {
  font-size: 1.5vw;
}

#date {
  margin-left: 78%;
  font-size: 1vw;
}
