.maincontent {
  min-height: 100vh;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  font-size: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maintext {
  font-size: 7vw;
  color: #66fcf1;
  text-shadow: 0 0 20px black, 0 0 20px black;
}

.scrolldown {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: gold;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

#arrowDown {
  width: 20%;
  height: 20%;
  animation: downUp 1s infinite;
}

@keyframes downUp {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-20%);
  }
}

@media screen and (max-width: 480px) {
  /* mobile devices */
  .maintext {
    font-size: 9vw;
  }
}
