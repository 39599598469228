.main-nav {
  background-color: #0b0c10;
  font-size: 1.5vw;
}

.navLogo {
  font-weight: bold;
  color: #66fcf1;
  width: 2vw;
}

@media screen and (max-width: 480px) {
  .main-nav {
    font-size: 2vw;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */
