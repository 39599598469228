@import url(https://fonts.googleapis.com/css?family=Bangers);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.Navbar {
  background: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.grid-box {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  align-items: center;
  justify-content: center;
}

.characters-header {
  color: white;
  padding: 2vh;
}

.charImg {
  width: 70vw;
}

.charactersMainInfo {
  padding-bottom: 130px;
  background-image: url(https://thumbs.dreamstime.com/z/create-fabric-pattern-pop-art-146577772.jpg);
}

.filter {
  color: white;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 1.5vh 1vw;
  margin: 2vh 0;
  border-radius: 10px;
  border-style: groove;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.character-names {
  display: none;
  color: white;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  font-size: 1rem;
}

#scale:hover {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
  transition-duration: 0.7s;
  z-index: 1;
}

#scale:hover .character-names {
  display: block;
}

.selection-shadow:hover {
  -webkit-backdrop-filter: opacity(100%);
          backdrop-filter: opacity(100%);
  box-shadow: 0px 100px 200px black inset;
}

.filter input {
  width: 100%;
}

.filter-word {
  font-weight: bold;
  letter-spacing: 3px;
}

.dropdown {
  font-size: small;
}

.dropdownToggle {
  margin: 0 5px;
}

.box {
  border-style: solid;
  box-shadow: 15px 15px 30px black;
}

.background-image {
  background-image: url(/static/media/superhero-pattern-1.2b13810a.jpg);
  width: 100%;
  height: 100%;
}

.charactersDiv {
  min-height: 100vh;
}

.search-bar {
  margin-left: auto;
}

/*
.selection-box {
  backdrop-filter: opacity(100%);
  box-shadow: 0 18.5vw 200px black inset;
  margin: 0 18.5vw;
}*/
.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

.heroName {
  font-size: 35px;
  font-weight: 550;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.powerInfo {
  margin-top: 4rem;
  width: auto;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

/* .heroInfo {
  margin-top: 5rem;
  color: black;
  border: 1px solid black;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  width: 50vw;
  height: fit-content;
} */

.heroName {
  font-size: 2.5rem;
  font-weight: 550;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.heroDescription {
  margin-top: 1rem;
  font-size: 20px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  text-align: justify;
}
.heroImage {
  margin-top: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

/* .bioContainer {
  backdrop-filter: blur(5px);
  margin-top: 2rem;
}*/
.bioInformation {
  justify-content: start;
  font-size: 24px;
  padding: 4px 0;
}

.heroInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  position: relative;
  border: solid 3px #6e7491;
  padding: 40px 60px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 500%;
  margin: 100px auto 0;
  margin-top: 5rem;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  font-family: "Bangers", cursive;
  font-size: 17px;
  line-height: 28px;
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
}

.heroInfo:hover {
  -webkit-transform: translateY(-10px) rotate(1deg);
          transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.heroInfo:hover .border {
  -webkit-transform: translateY(4px) rotate(-5deg);
          transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.wordPowers {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 2rem;
}

.powerInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  justify-content: center;
  align-content: center;
  position: relative;
  border: solid 3px #6e7491;
  max-width: 1250px;
  width: 100%;

  background-color: white;

  font-family: "Bangers", cursive;
  font-size: 28px;
  line-height: 28px;
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
}

.powerInfo:hover {
  -webkit-transform: translateY(-10px) rotate(1deg);
          transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.powerInfo:hover .border {
  -webkit-transform: translateY(4px) rotate(-5deg);
          transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

.heroInfo {
  margin-top: 5rem;
  margin-left: 100px;
  color: black;
  border: 1px solid black;
  background-color: white;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  width: 50vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.heroName {
  font-size: 28px;
  font-weight: 550;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.heroDescription {
  margin-top: 1rem;
  font-size: 20px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  text-align: justify;
}
.heroImage {
  margin-top: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

/* .bioContainer {
  backdrop-filter: blur(5px);
  margin-top: 2rem;
}*/
.bioInformation {
  font-size: 24px;
}

.heroInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  position: relative;
  border: solid 3px #6e7491;
  padding: 40px 60px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 500%;
  margin: 100px auto 0;

  font-family: "Bangers", cursive;
  font-size: 17px;
  line-height: 28px;
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
}

.heroInfo:hover {
  -webkit-transform: translateY(-10px) rotate(1deg);
          transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.heroInfo:hover .border {
  -webkit-transform: translateY(4px) rotate(-5deg);
          transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.wordPowers {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 2rem;
}

.powerInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  justify-content: center;
  align-content: center;
  position: relative;
  border: solid 3px #6e7491;
  max-width: 1250px;
  width: 100%;
  margin: 100px auto 0;
  background-color: white;

  font-family: "Bangers", cursive;
  font-size: 28px;
  line-height: 28px;
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
}

.powerInfo:hover {
  -webkit-transform: translateY(-10px) rotate(1deg);
          transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.powerInfo:hover .border {
  -webkit-transform: translateY(4px) rotate(-5deg);
          transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

@media screen and (max-width: 1024px) {
  .heroInfo {
    width: 500%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
    max-width: 900px;
  }

  .heroImage {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .heroInfo {
    width: 500%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
    max-width: 650px;
  }

  .heroImage {
    width: 500px;
  }
}

@media screen and (max-width: 425px) {
  .heroInfo {
    width: 80%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 200px;
  }

  .powerInfo {
    width: 80%;
    height: 1300px;
  }

  .circularProgressbar {
    width: 300px;
    height: 300px;
    margin: 30px;
  }
}

@media screen and (max-width: 375px) {
  .heroInfo {
    width: 90%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 200px;
  }

  .powerInfo {
    width: 90%;
    height: 1400px;
  }

  .circularProgressbar {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .heroInfo {
    width: 70%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 130%;
    height: auto;
  }

  .powerInfo {
    width: 70%;
    height: 1300px;
  }

  .circularProgressbar {
    width: 200px;
    height: 200px;
    margin-left: 40px;
  }
}
@media screen and (max-width: 480px) {
  /* mobile devices */
  .grid-box {
    grid-template-columns: auto auto;
  }
  .characters-header {
    padding-top: 5vh;
  }
  .charImg {
    width: 80vw;
  }
  .filter {
    color: white;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 2vh 2vw;
    border-radius: 10px;
    border-style: groove;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
  }
  .dropdownToggle {
    margin: 5px 0;
  }
  .search-bar {
    margin: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .grid-box {
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .grid-box {
    grid-template-columns: auto auto auto auto auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .grid-box {
    grid-template-columns: auto auto auto auto auto auto;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

.char-arrange {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  border-radius: 0.7rem;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

.hero-box {
  margin: 5px 5px;
}

.hero-box:hover {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
  transition-duration: 1s;
  z-index: 1;
}

.hero-box:hover .character-names-versus {
  display: block;
}

.character-names-versus {
  display: none;
  color: white;
  font-size: 14px;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
}

.pick-hero {
  display: flex;
  justify-content: space-between;
}

.left-hero {
  width: 400px;
  height: 450px;
  margin: 40px 40px 65px 15px;
  border-radius: 1rem;
  background-color: rgb(0, 0, 0, 0.5);
}

.left-sp {
  width: 400px;
  height: 450px;
  border-radius: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.right-hero {
  width: 400px;
  height: 450px;
  margin: 40px 15px 65px 30px;
  border-radius: 1rem;
  background-color: rgb(0, 0, 0, 0.5);
}

.right-sp {
  width: 400px;
  height: 450px;
  border-radius: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.first-box {
  display: flex;
  align-content: flex-end;
}

.right-arrange {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
}

.second-box {
  display: flex;
  align-content: flex-start;
}

.stats-left-hero1 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 150px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-left-hero2 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-right-hero1 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.stats-right-hero2 {
  height: 140px;
  width: 140px;
  font-size: 1vw;
  padding: 50px 0 100px 0;
  color: white;
  border: none;
  background: none;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.leftImageOverlay {
  display: flex;
  color: white;
  justify-content: flex-start;
  align-content: flex-start;
}

.player1Name {
  font-size: 2vw;
  color: black;
  text-shadow: 0 0 10px white, 0 0 10px white;
  font-family: "Bangers", cursive;
}

.player2Name {
  font-size: 2vw;
  color: black;
  text-shadow: 0 0 10px white, 0 0 10px white;
  font-family: "Bangers", cursive;
}

.rightImageOverlay {
  display: flex;
  color: white;
  justify-content: flex-end;
  align-content: flex-end;
}

.versus-image {
  margin-top: 10vh;
  width: 50%;
  height: 50%;
}

.overall-pos1 {
  padding-top: 10px;
  font-size: 2vw;
  color: white;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.overall-pos2 {
  padding-top: 10px;
  font-size: 2vw;
  color: white;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
}

.background {
  background-image: url("https://cutewallpaper.org/21/fire-background-gif/Fire-Fighting-GIF-Find-Share-on-GIPHY.gif");
  background-size: cover;
  position: -webkit-sticky;
  position: sticky;
}

.mid-section {
  color: white;
  padding-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar-versus {
  background-color: rgb(0, 0, 0, 0.6);
  padding-top: 3px;
  border-radius: 5px;
  width: 13.5vw;
  height: 4vh;
}

@media screen and (max-width: 480px) {
  .char-arrange {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto auto;
    border-radius: 0.7rem;
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
  }
  .left-hero {
    width: 100px;
    height: 120px;
    margin: 40px 5px 65px 5px;
    border-radius: 1rem;
    background-color: rgb(0, 0, 0, 0.5);
  }
  .left-sp {
    width: 100px;
    height: 120px;
    border-radius: 1rem;
    box-shadow: 2px 3px 5px 1px;
  }
  .stats-left-hero1 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 150px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-left-hero2 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-right-hero1 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }

  .stats-right-hero2 {
    height: 30px;
    width: 30px;
    font-size: 1vw;
    padding: 50px 0 100px 0;
    color: white;
    border: none;
    background: none;
    text-shadow: 0 0 10px black, 0 0 10px black;
    font-family: "Bangers", cursive;
  }
  .versus-image {
    margin-top: 8vh;
    width: 30px;
    height: 30px;
  }
  .right-hero {
    width: 100px;
    height: 120px;
    margin: 40px 5px 65px 5px;
    border-radius: 1rem;
    background-color: rgb(0, 0, 0, 0.5);
  }

  .right-sp {
    width: 100px;
    height: 120px;
    border-radius: 1rem;
    box-shadow: 2px 3px 5px 1px;
  }
  .versus-div {
    width: 100vw;
  }
  .search-bar-versus {
    background-color: rgb(0, 0, 0, 0.6);
    padding-top: 3px;
    border-radius: 5px;
    width: 60vw;
    height: 5vh;
  }
}

.features {
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
}

.header1 {
  width: auto;
  height: auto;
  color: #66fcf1;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  padding-bottom: 20px;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.featureCards {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.lists {
  color: white;
  background: none;
  font-size: 2vw;
}

.vsImage {
  width: 5vw;
}

.cardFeatures {
  color: white;
  display: grid;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 10px;
  border-radius: 0.6rem;
  border-color: #1f2833;
  background-image: url(/static/media/blue-abstract-rays-4k-blue-lines-creative-blue-abstract-backgrounds.0ddd199b.jpg);
  background-size: cover;
  text-shadow: 0 0 10px black, 0 0 10px black;
  margin: 0 10px;
  object-fit: cover;
  height: 40vh;
  width: 30vw;
}

.CardTitle {
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 2.5vw;
  text-shadow: 0 0 10px white, 0 0 10px white;
}

.cardFeatures:hover {
  color: #66fcf1;
}
.lists:hover {
  color: #66fcf1;
}

.CardText {
  font-size: 2vw;
  padding: 0 20px;
}

@media screen and (max-width: 480px) {
  /* mobile devices */
  .featureCards {
    display: grid;
    grid-template-columns: auto;
  }

  .cardFeatures {
    margin: 10px 0;
    height: 40vh;
    width: 40vw;
  }

  .CardTitle {
    font-size: 4vw;
  }

  .CardText {
    font-size: 3vw;
    padding: 0 10px;
  }

  .lists {
    font-size: 3vw;
  }
  .header1 {
    font-size: 10vw;
    padding-bottom: 10px;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

.maincontent {
  min-height: 100vh;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  font-size: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maintext {
  font-size: 7vw;
  color: #66fcf1;
  text-shadow: 0 0 20px black, 0 0 20px black;
}

.scrolldown {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: gold;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

#arrowDown {
  width: 20%;
  height: 20%;
  -webkit-animation: downUp 1s infinite;
          animation: downUp 1s infinite;
}

@-webkit-keyframes downUp {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
}

@keyframes downUp {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
}

@media screen and (max-width: 480px) {
  /* mobile devices */
  .maintext {
    font-size: 9vw;
  }
}

.bgHome {
  background-image: url(/static/media/abcdef.d8f926e4.jpg);
  background-size: cover;
  position: -webkit-sticky;
  position: sticky;
}

.HeroDisplay {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 2vmin);
  font-weight: bolder;
  color: #66fcf1;
}

.HeroCards {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.HeroDisplay > h1 {
  font-size: 5vw;
  color: #66fcf1;
  text-shadow: 0 0 10px black, 0 0 10px black;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  font-size: 4vw;
  background: none;
}

.heroCard {
  margin: 0 10px;
  border: none;
  border-radius: 0.6rem;
}

.heroCard:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  position: relative;
  box-shadow: 0 10px 20px #66fcf1, 0 10px 20px #66fcf1;
  text-shadow: 0 0 2px #66fcf1, 0 0 2px #66fcf1;
}

.character-names-home {
  display: flex;
  justify-content: center;
  background: none;
  color: black;
  font-size: 2vw;
  font-weight: bolder;
  border-radius: 0.4rem;
  padding: 5px 5px;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  text-shadow: 0 0 10px white, 0 0 10px white;
}

@media screen and (max-width: 480px) {
  /* mobile devices */
  .HeroCards {
    grid-template-columns: auto;
  }
  .heroCard {
    margin: 0 0 20px 0;
  }
  .character-names-home {
    font-size: 5vw;
  }

  .HeroDisplay > h1 {
    font-size: 9vw;
    padding: 10px;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

.bg {
  background-image: url(/static/media/bgf.77fb8741.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.comment-box {
  background-color: black;
  box-shadow: 20px 20px 10px 0 rgb(0, 0, 0, 0.2);
  color: #66fcf1;
  width: 80%;
  margin-left: 10%;
  transition: 0.3s;
  /* display: inline-flex; */
  padding: 2rem;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
.comment-box:hover {
  opacity: 1;
}

.haha {
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

.username-box {
  width: 90%;
  margin-left: 5%;
  margin-top: 50px;
  margin-bottom: 80px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 8px 10px rgba(102, 252, 241, 0.2);
}

#incomment {
  width: 70%;
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 25px;
  text-align: center;
}

.namein {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  text-align: center;
  border-radius: 25px;
  width: 15rem;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .namein {
    width: 10rem;
  }

  #incomment {
    text-align: center;
  }

  #commentbtn {
    width: 5rem;
  }
}

@media screen and (max-width: 300px) and (min-width: 0px) {
  .namein {
    width: 6rem;
  }
  #commentbtn {
    width: 2rem;
    -webkit-text-size-adjust: 2rem;
       -moz-text-size-adjust: 2rem;
            text-size-adjust: 2rem;
  }
}

#commentbtn {
  margin-top: 15px;
  margin-bottom: 15px;
}

.usercomments-box {
  box-shadow: 10px 10px 8px 0 rgba(255, 255, 255, 0.2);
  width: 80%;
  margin-left: 10%;
  transition: 0.3s;
  color: #45a29e;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.usercomments-box:hover::before {
  /* transform: scale(1.1); */
  box-shadow: 0 0 15px #66fcf1;
}

.usercomments-box:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  color: #66fcf1;
  box-shadow: 0 0 5px #66fcf1;
  text-shadow: 0 0 5px #66fcf1;
}

#name {
  font-size: 2vw;
}

#comment {
  font-size: 1.5vw;
}

#date {
  margin-left: 78%;
  font-size: 1vw;
}

.aboutContainer {
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", serif;
  color: #000000;
  /* color: #0b0c10;
  color: #1F2833;
  color:#C5C6C7;
  color: #66FCF1;
  color: #45A29E; */
}

.Para1 {
  font-family: "Roboto", serif;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b0c10;
  padding: 0 20px 20px 20px;
}

.aboutHeader {
  font-family: "Bungee Inline", cursive;
  color: #1f2833;
  font-size: 4vw;
}

.image {
  width: 20rem;
  height: 20rem;
  margin-bottom: 50px;
}

.AboutBack {
  background-image: url(/static/media/AboutBack9.2859fe8e.png);
}

.box {
  margin: auto;
  border-style: groove;
  width: 80%;
}

.CTBRS {
  font-family: "Bungee Inline", cursive;
  font-size: 4vw;
  color: #8080ff;
}

.namesHeader {
  font-size: 2vw;
}

.main-nav {
  background-color: #0b0c10;
  font-size: 1.5vw;
}

.navLogo {
  font-weight: bold;
  color: #66fcf1;
  width: 2vw;
}

@media screen and (max-width: 480px) {
  .main-nav {
    font-size: 2vw;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

