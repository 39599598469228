@import url(https://fonts.googleapis.com/css?family=Bangers);

.grid-box {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  align-items: center;
  justify-content: center;
}

.characters-header {
  color: white;
  padding: 2vh;
}

.charImg {
  width: 70vw;
}

.charactersMainInfo {
  padding-bottom: 130px;
  background-image: url(https://thumbs.dreamstime.com/z/create-fabric-pattern-pop-art-146577772.jpg);
}

.filter {
  color: white;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 1.5vh 1vw;
  margin: 2vh 0;
  border-radius: 10px;
  border-style: groove;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.character-names {
  display: none;
  color: white;
  font-family: "Bangers", cursive;
  font-family: "Bungee Inline", cursive;
  font-size: 1rem;
}

#scale:hover {
  transform: scale(1.2, 1.2);
  transition-duration: 0.7s;
  z-index: 1;
}

#scale:hover .character-names {
  display: block;
}

.selection-shadow:hover {
  backdrop-filter: opacity(100%);
  box-shadow: 0px 100px 200px black inset;
}

.filter input {
  width: 100%;
}

.filter-word {
  font-weight: bold;
  letter-spacing: 3px;
}

.dropdown {
  font-size: small;
}

.dropdownToggle {
  margin: 0 5px;
}

.box {
  border-style: solid;
  box-shadow: 15px 15px 30px black;
}

.background-image {
  background-image: url(../../superhero-pattern-1.jpg);
  width: 100%;
  height: 100%;
}

.charactersDiv {
  min-height: 100vh;
}

.search-bar {
  margin-left: auto;
}

/*
.selection-box {
  backdrop-filter: opacity(100%);
  box-shadow: 0 18.5vw 200px black inset;
  margin: 0 18.5vw;
}*/
.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

.heroName {
  font-size: 35px;
  font-weight: 550;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.powerInfo {
  margin-top: 4rem;
  width: auto;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

/* .heroInfo {
  margin-top: 5rem;
  color: black;
  border: 1px solid black;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  width: 50vw;
  height: fit-content;
} */

.heroName {
  font-size: 2.5rem;
  font-weight: 550;
  backdrop-filter: blur(5px);
}

.heroDescription {
  margin-top: 1rem;
  font-size: 20px;
  backdrop-filter: blur(5px);
  text-align: justify;
}
.heroImage {
  margin-top: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

/* .bioContainer {
  backdrop-filter: blur(5px);
  margin-top: 2rem;
}*/
.bioInformation {
  justify-content: start;
  font-size: 24px;
  padding: 4px 0;
}

.heroInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  position: relative;
  border: solid 3px #6e7491;
  padding: 40px 60px;
  max-width: fit-content;
  width: 500%;
  margin: 100px auto 0;
  margin-top: 5rem;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  height: fit-content;

  font-family: "Bangers", cursive;
  font-size: 17px;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
}

.heroInfo:hover {
  transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.heroInfo:hover .border {
  transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.wordPowers {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 2rem;
}

.powerInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  justify-content: center;
  align-content: center;
  position: relative;
  border: solid 3px #6e7491;
  max-width: 1250px;
  width: 100%;

  background-color: white;

  font-family: "Bangers", cursive;
  font-size: 28px;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
}

.powerInfo:hover {
  transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.powerInfo:hover .border {
  transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.charactersMainInfo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
}

.charactersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto Condensed", sans-serif;
}

.heroInfo {
  margin-top: 5rem;
  margin-left: 100px;
  color: black;
  border: 1px solid black;
  background-color: white;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
  width: 50vw;
  height: fit-content;
}

.heroName {
  font-size: 28px;
  font-weight: 550;
  backdrop-filter: blur(5px);
}

.heroDescription {
  margin-top: 1rem;
  font-size: 20px;
  backdrop-filter: blur(5px);
  text-align: justify;
}
.heroImage {
  margin-top: 1rem;
  box-shadow: 2px 3px 5px 1px;
}

.charactersPower {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 4rem;
}

.charactersNavbar {
  justify-content: center;
  align-content: center;
}

/* .bioContainer {
  backdrop-filter: blur(5px);
  margin-top: 2rem;
}*/
.bioInformation {
  font-size: 24px;
}

.heroInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  position: relative;
  border: solid 3px #6e7491;
  padding: 40px 60px;
  max-width: fit-content;
  width: 500%;
  margin: 100px auto 0;

  font-family: "Bangers", cursive;
  font-size: 17px;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
}

.heroInfo:hover {
  transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.heroInfo:hover .border {
  transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

.wordPowers {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 2rem;
}

.powerInfo {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  display: block;
  justify-content: center;
  align-content: center;
  position: relative;
  border: solid 3px #6e7491;
  max-width: 1250px;
  width: 100%;
  margin: 100px auto 0;
  background-color: white;

  font-family: "Bangers", cursive;
  font-size: 28px;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
  transition: all 0.13s ease-in;
  background-image: url(https://wallpaperaccess.com/full/876546.jpg);
}

.powerInfo:hover {
  transform: translateY(-10px) rotate(1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.powerInfo:hover .border {
  transform: translateY(4px) rotate(-5deg);
}

.border {
  position: absolute;
  transition: all 0.13s ease-in;
}

.border:before,
.border:after {
  color: #515d9c;
  font-size: 15px;
  position: absolute;
}

.tl {
  position: absolute;
  left: -50px;
  top: -63px;
  font-weight: 600;
}

.tl:before {
  left: 120px;
  top: 30px;
}

.tl:after {
  left: 0px;
  top: 80px;
}

.tr {
  right: -50px;
  top: -63px;
  font-weight: 600;
}

.tr:before {
  left: 0;
  top: 30px;
}

.tr:after {
  left: 130px;
  top: 80px;
}

.bl {
  left: -50px;
  bottom: -71px;
  font-weight: 600;
}

.bl:before {
  left: 120px;
  top: -30px;
}

.bl:after {
  left: 0px;
  top: -90px;
}

.br {
  right: -50px;
  bottom: -63px;
  font-weight: 600;
}

.br:before {
  left: 0;
  top: -30px;
}

.br:after {
  right: -10px;
  top: -80px;
}

@media screen and (max-width: 1024px) {
  .heroInfo {
    width: 500%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
    max-width: 900px;
  }

  .heroImage {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .heroInfo {
    width: 500%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
    max-width: 650px;
  }

  .heroImage {
    width: 500px;
  }
}

@media screen and (max-width: 425px) {
  .heroInfo {
    width: 80%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 200px;
  }

  .powerInfo {
    width: 80%;
    height: 1300px;
  }

  .circularProgressbar {
    width: 300px;
    height: 300px;
    margin: 30px;
  }
}

@media screen and (max-width: 375px) {
  .heroInfo {
    width: 90%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 200px;
  }

  .powerInfo {
    width: 90%;
    height: 1400px;
  }

  .circularProgressbar {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .heroInfo {
    width: 70%;
    font-size: 20px;
    margin: 20px 20px 0 20px;
  }

  .heroImage {
    width: 130%;
    height: auto;
  }

  .powerInfo {
    width: 70%;
    height: 1300px;
  }

  .circularProgressbar {
    width: 200px;
    height: 200px;
    margin-left: 40px;
  }
}
@media screen and (max-width: 480px) {
  /* mobile devices */
  .grid-box {
    grid-template-columns: auto auto;
  }
  .characters-header {
    padding-top: 5vh;
  }
  .charImg {
    width: 80vw;
  }
  .filter {
    color: white;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 2vh 2vw;
    border-radius: 10px;
    border-style: groove;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
  }
  .dropdownToggle {
    margin: 5px 0;
  }
  .search-bar {
    margin: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .grid-box {
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .grid-box {
    grid-template-columns: auto auto auto auto auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .grid-box {
    grid-template-columns: auto auto auto auto auto auto;
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */
